<template>
  <div class="right-echarts" >
    <div style="height: 100%; width: 100%" ref="mapRightEchart" @click="rightMapClick"></div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watch, onMounted, ref, computed } from 'vue'
import { EnvironmentOutlined } from '@ant-design/icons-vue'
import echarts from 'echarts'
import 'echarts/map/js/china'
export default defineComponent({
  name: 'mapRightEcharts',
  components: {
    EnvironmentOutlined,
  },
  props: {
    mapData: Array, //数据
    visualMap: Object, //数据范围
    showProvince: Boolean, //是否展示省
    legend: Object, //图列
    type: Boolean,
    center: Array,
  },
  setup(props) {
    const mapRightEchart = ref()
    let myChartRight = ref()
    let options = ref()
    const chinaConfigure = () => {
      myChartRight = echarts.init(mapRightEchart.value) //这里是为了获得容器所在位置
      options = {
        backgroundColor: '#ffffff',
        title: {},
        tooltip: {
          // 鼠标移到图里面的浮动提示框
          trigger: 'item',
          formatter: (params) => {
            // console.log(params)
            return `<div class='block'><p class='province'><i class='iconfont icon-weizhi' style='margin-right:5px;font-size:12px;color:rgba(0,0,0,.65)'></i>${params.data.showName}</p><p class='num'><span>用印次数</span><span style='float:right'>${params.data.value}</span></p></div>` //{c@[2]}
          },
          // formatter: `<div style='background: url( ${require('@/assets/images/map-bg.png')}) no-repeat center center;padding:10px 0 15px'><p class='province'><i class='iconfont icon-weizhi' style='margin-right:5px;font-size:12px;color:rgba(0,0,0,.65);margin-left:-10px'></i>{b}</p><p class='num'>印章数量：{c}</p></div>`, //{c@[2]}
          show: true,
          backgroundColor: 'rgba(255,255,255,0)',
          padding: [0, 0],
          position: function (point, params, dom, rect, size) {
            return [point[0] - 50, point[1] - 80]
          },
          textStyle: {
            color: 'rgba(0,0,0,.45)',
          },
        },
        visualMap: props.visualMap || [],
        geo: {
          // 这个是重点配置区
          map: 'china', // 表示中国地图
          roam: true, //允许放大缩小
          zoom: 1.2,
          scaleLimit: { min: 1, max: 25 }, //限制放大缩小倍数
          show: true,
          label: {
            normal: {
              show: props.showProvince || false, // 是否显示对应地名
              textStyle: {
                color: 'rgba(0,0,0,0.45)',
                fontSize: 8,
              },
            },
            emphasis: {
              color: 'rgba(255,255,255,0)',
            },
          },
          itemStyle: {
            borderWidth: 1,
            normal: {
              borderColor: 'rgba(0, 0, 0, 0.2)',
            },
            emphasis: {
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 0,
              borderWidth: 1,
              areaColor: '#f3faff',
              shadowColor: "rgba(0, 0, 0, 0.2)",
            },
          },
        },
        series: [
          {
            // name: '启动次数', // 浮动框的标题
            type: 'map',
            coordinateSystem: 'geo', // 对应上方配置
            geoIndex: 0,
            data: props.mapData,
          },
        ],
      }
      if (props.legend) options.legend = props.legend
      myChartRight.setOption(options)
      window.addEventListener('resize', () => {
        // 随屏幕宽度大小改变echarts
        myChartRight.resize()
      })
    }
    const rightMapClick = () => {
      myChartRight.setOption({
        geo: {
          center: [104.3, 35.9],
        },
      })
    }
    onMounted(() => {
      chinaConfigure()
    })
    return {
      // ...toRefs(state),
      mapRightEchart,
      rightMapClick
    }
  },
})
</script>

<style lang="scss">
.right-echarts {
  width: 100%;
  height: 317px;
  min-height: 317px;
  margin: 0 auto;
  .block {
    min-width: 96px;
    padding: 4px 0;
    background: url('../../../assets/images/map-bg1.png') no-repeat;
    background-size: 100% 100%;
  }
  .province {
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #f5f5f5;
    padding: 5px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }
  .num {
    font-size: 12px;
    padding: 5px 5px 10px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
